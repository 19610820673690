<template>
  <div class="gyro-wrapper p-3">
    <gyro-access 
          @moved="UpdateGyro"
          @callibrated="callibrated"
          @gyroPermissionDenied="noGyroPermission"
          @gyroStarted="gyroStarted"
          @noGyroAvailable="noGyroAvailable"
          :range="block.range ?? 20"
          v-slot="gyro" ref="myGyro">
      <pre v-if="$parent.ShowDebugger" class="mb-4 p-2" style="position: absolute; opacity: .5;">{{ gyro }}</pre>
      <div
        class="is-flex is-flex-direction-column requestGyro">
        <div v-if="block.showGraphicTutorial" class="tutorial is-flex is-justify-content-center mb-5">
          <svg class="img-tuto" viewBox="0 0 230 193" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_242_16529)">
              <g opacity="0.9">
                <path
                  d="M227.513 140.551L227.333 140.514C226.892 139.591 226.179 138.764 225.212 138.118L225.167 138.088L91.0154 60.3595C87.1264 58.0904 82.4053 57.9413 78.3968 59.9708L18.7063 94.8046C17.7492 95.5069 16.9986 96.3899 16.487 97.359H16.4804C10.32 107.69 18.7812 113.005 18.7812 113.005L152.429 190.526C156.006 192.615 160.385 192.764 164.082 190.898L223.728 156.139C223.728 156.139 223.758 156.125 223.832 156.08C224.634 155.632 225.286 155.109 225.776 154.557C227.899 152.467 231.284 146.733 227.513 140.552V140.551Z"
                  fill="#F5F5F5" />
              </g>
              <path
                d="M153.512 178.908C154.937 178.908 156.378 178.579 157.698 177.907L216.93 143.656C218.577 142.671 219.498 141.327 219.542 139.864C219.571 138.565 218.888 137.341 217.671 136.52L94.4307 65.1965C91.6552 63.5687 88.2703 63.4642 85.3905 64.9134L26.1746 99.194C24.8091 99.8811 23.9629 101.225 23.9184 102.762C23.8738 104.3 24.631 105.689 25.9671 106.465L148.822 177.625C150.277 178.476 151.895 178.909 153.528 178.909H153.513L153.512 178.908Z"
                fill="#E0E0E0" />
              <path
                d="M158.604 193C156.334 193 154.068 192.401 152.029 191.211L18.3848 113.692C18.3772 113.687 18.3685 113.682 18.3609 113.678C18.2675 113.619 16.0449 112.201 14.6164 109.418C12.2265 104.762 13.9407 100.067 15.8005 96.9477C16.0253 96.571 16.512 96.4479 16.8879 96.6733C17.2637 96.8987 17.3865 97.3865 17.1616 97.7632C14.7717 101.771 14.3796 105.428 15.996 108.631C17.1725 110.963 19.0453 112.226 19.1952 112.325L152.827 189.836C156.186 191.798 160.25 191.932 163.705 190.196L223.33 155.449C223.361 155.432 223.359 155.43 223.387 155.419C223.395 155.413 223.408 155.407 223.424 155.397C223.431 155.393 223.439 155.388 223.446 155.384C224.149 154.991 224.735 154.534 225.185 154.026C225.196 154.013 225.208 154 225.221 153.988C227.288 151.953 230.284 146.613 226.838 140.964C226.61 140.589 226.727 140.1 227.101 139.87C227.474 139.641 227.962 139.759 228.191 140.134C232.147 146.618 228.728 152.748 226.352 155.102C225.79 155.731 225.076 156.291 224.232 156.765C224.186 156.792 224.144 156.816 224.108 156.834L164.482 191.581C164.467 191.589 164.454 191.596 164.439 191.604C162.596 192.535 160.599 192.997 158.605 192.997L158.604 193Z"
                fill="#0F0F0F" />
              <path
                d="M158.648 185.107C156.199 185.107 153.787 184.45 151.67 183.207L18.0272 105.689C16.1305 104.572 15.0323 102.788 14.9302 100.664C14.8118 98.198 16.048 95.7677 18.238 94.1617L18.3075 94.116L78.0187 59.2702L78.0393 59.2593C82.2857 57.1078 87.286 57.2613 91.414 59.6698L225.589 137.412L225.658 137.459C227.597 138.752 228.683 140.709 228.632 142.822C228.564 145.174 227.13 147.302 224.593 148.816L164.876 183.606L164.855 183.616C162.933 184.591 160.788 185.106 158.648 185.106V185.107ZM19.1429 95.4694C17.4308 96.74 16.4249 98.6988 16.5151 100.589C16.5911 102.159 17.4113 103.483 18.8268 104.317L152.469 181.835C154.346 182.938 156.482 183.52 158.648 183.52C160.532 183.52 162.423 183.068 164.119 182.212L223.788 147.451C225.835 146.228 226.994 144.57 227.046 142.784C227.084 141.232 226.255 139.772 224.771 138.782L224.744 138.763L90.6188 61.0472C86.9612 58.9131 82.5366 58.7737 78.7758 60.6694L19.1429 95.4694Z"
                fill="#0F0F0F" />
              <path
                d="M39.0594 122.069C39.1485 122.069 39.2224 122.069 39.3114 122.04C39.9198 121.86 40.2174 121.143 40.0088 120.397C39.8904 119.994 39.4744 119.605 39.4005 119.531L34.6055 116.709C34.3383 116.575 34.1004 116.559 33.8636 116.619C33.6116 116.693 33.403 116.873 33.2553 117.112C33.0771 117.44 33.0326 117.858 33.151 118.261C33.2694 118.695 33.567 119.053 33.9233 119.217L38.5249 121.92C38.7324 122.024 38.8965 122.069 39.0594 122.069V122.069Z"
                fill="#0F0F0F" />
              <path
                d="M31.4301 117.544C31.5192 117.544 31.5931 117.544 31.668 117.514C32.2764 117.335 32.5882 116.618 32.3655 115.871C32.247 115.468 31.831 115.081 31.7571 115.006L26.9622 112.183C26.6949 112.049 26.457 112.034 26.2202 112.094C25.9682 112.169 25.7596 112.348 25.6119 112.587C25.4337 112.915 25.4044 113.333 25.5076 113.736C25.626 114.169 25.9236 114.528 26.2799 114.692L30.8816 117.394C31.089 117.499 31.2531 117.544 31.416 117.544H31.4312H31.4301Z"
                fill="#0F0F0F" />
              <path
                d="M43.587 124.621C43.587 124.621 43.7206 124.621 43.7804 124.592C44.0921 124.501 44.33 124.263 44.4636 123.934C44.5821 123.636 44.5821 123.277 44.493 122.948C44.3746 122.546 44.1367 122.217 43.8097 122.038L42.6517 121.381C42.4583 121.307 42.1466 121.231 41.9097 121.291C41.6577 121.366 41.4491 121.545 41.3014 121.785C41.1232 122.112 41.0787 122.53 41.1971 122.934C41.3155 123.367 41.7913 123.771 41.8804 123.845L43.0384 124.501C43.1872 124.547 43.3795 124.606 43.5729 124.606L43.5881 124.621H43.587Z"
                fill="#0F0F0F" />
              <path
                d="M156.69 192.391L157.863 191.899C156.497 188.629 157.863 184.777 157.863 184.732L156.675 184.299C156.616 184.477 155.116 188.674 156.675 192.391H156.69Z"
                fill="#0F0F0F" />
              <path
                d="M192.318 172.188C192.526 172.188 192.748 172.129 192.956 172.009L197.158 169.56C197.855 169.157 198.39 168.127 198.39 167.216C198.39 166.648 198.182 166.201 197.811 165.992C197.484 165.798 197.069 165.828 196.682 166.052L192.481 168.501C191.784 168.903 191.249 169.933 191.249 170.845C191.249 171.412 191.457 171.86 191.828 172.069C191.977 172.158 192.14 172.203 192.318 172.203V172.188V172.188Z"
                fill="#0F0F0F" />
              <path
                d="M61.8316 75.2298L61.9055 75.1851C62.2911 74.9608 62.2466 74.558 61.8012 74.3043C61.3558 74.0353 60.6736 74.0059 60.2716 74.2291L60.1978 74.2738C59.8121 74.4981 59.8567 74.901 60.3021 75.1547C60.7474 75.4236 61.4449 75.453 61.8316 75.2298Z"
                fill="#0F0F0F" />
              <path
                d="M46.0226 84.3359L59.62 76.4975C60.0056 76.2732 59.9611 75.8703 59.5157 75.6166C59.0703 75.3477 58.3881 75.3183 57.9873 75.5426L44.3899 83.381C44.0043 83.6053 44.0488 84.0082 44.4942 84.2619C44.9396 84.5308 45.6229 84.5602 46.0237 84.337L46.0226 84.3359Z"
                fill="#0F0F0F" />
              <path
                d="M158.621 181.289C160.046 181.289 161.486 180.961 162.807 180.289L222.038 146.038C223.686 145.052 224.606 143.709 224.651 142.245C224.68 140.946 223.997 139.722 222.78 138.901L94.4307 65.1965C91.6552 63.5687 88.2703 63.4642 85.3905 64.9134L26.1746 99.194C24.8091 99.8811 23.9629 101.225 23.9184 102.762C23.8738 104.3 24.631 105.689 25.9671 106.465L153.931 180.006C155.386 180.856 157.004 181.289 158.637 181.289H158.622H158.621Z"
                fill="#E0E0E0" />
              <path
                d="M107.851 127.614C107.632 127.614 107.419 127.5 107.3 127.296C107.124 126.993 107.227 126.603 107.532 126.426L133.42 111.391C133.724 111.214 134.112 111.318 134.288 111.623C134.464 111.926 134.361 112.316 134.057 112.493L108.168 127.528C108.068 127.587 107.958 127.614 107.85 127.614H107.851Z"
                fill="white" />
              <path
                d="M94.787 120.03C94.5675 120.03 94.3546 119.916 94.2362 119.712C94.0602 119.409 94.1634 119.018 94.4676 118.842L120.357 103.807C120.661 103.63 121.049 103.734 121.225 104.039C121.4 104.342 121.297 104.733 120.993 104.909L95.1042 119.944C95.0042 120.003 94.8945 120.03 94.7859 120.03H94.787Z"
                fill="white" />
              <path
                d="M97.5636 126.003C97.3442 126.003 97.1312 125.889 97.0128 125.685C96.8368 125.38 96.94 124.991 97.2442 124.815L130.645 105.42C130.948 105.244 131.337 105.347 131.513 105.652C131.689 105.957 131.586 106.347 131.282 106.522L97.8808 125.917C97.7808 125.976 97.6711 126.003 97.5625 126.003H97.5636Z"
                fill="white" />
              <path
                d="M151.331 155.168L180.44 138.266C182.994 136.789 182.667 134.205 179.728 132.488C176.789 130.771 172.335 130.592 169.783 132.07L140.672 148.972C138.119 150.45 138.445 153.033 141.384 154.75C144.324 156.467 148.777 156.646 151.331 155.168Z"
                fill="#242424" />
              <path
                d="M117.003 0.319298C116.58 -0.106433 115.891 -0.106433 115.467 0.319298L108.554 7.24859C108.129 7.67432 108.129 8.36355 108.554 8.78819C108.978 9.21392 109.666 9.21392 110.09 8.78819L116.235 2.6287L122.38 8.78819C122.804 9.21392 123.493 9.21392 123.917 8.78819C124.341 8.36246 124.341 7.67323 123.917 7.24859L117.003 0.319298V0.319298ZM117.322 118.683V1.0891H115.149V118.682H117.322V118.683Z"
                fill="var(--arrow-y)" />
              <path
                d="M195.484 78.7292C195.667 78.1565 195.353 77.5435 194.782 77.3594L185.473 74.363C184.902 74.179 184.29 74.4947 184.107 75.0664C183.923 75.6391 184.237 76.2521 184.809 76.4361L193.083 79.0994L190.426 87.393C190.242 87.9657 190.557 88.5787 191.129 88.7627C191.7 88.9467 192.312 88.631 192.495 88.0593L195.484 78.7292ZM116.732 119.652L194.946 79.3651L193.953 77.428L115.739 117.715L116.732 119.652Z"
                fill="var(--arrow-x)" />
              <path
                d="M0.807023 51.211C0.226933 51.3656 -0.117427 51.9623 0.0368293 52.5437L2.55272 62.0132C2.70698 62.5947 3.30228 62.9398 3.88237 62.7852C4.46246 62.6306 4.80682 62.0339 4.65256 61.4525L2.41693 53.0348L10.8152 50.794C11.3953 50.6393 11.7396 50.0427 11.5854 49.4612C11.4311 48.8798 10.8358 48.5346 10.2557 48.6893L0.807023 51.211ZM117.866 118.828L1.63153 51.321L0.541963 53.2046L116.777 120.712L117.866 118.827V118.828Z"
                fill="var(--arrow-z)" />
              <path
                d="M116.235 120.859C117.435 120.859 118.408 119.884 118.408 118.682C118.408 117.479 117.435 116.504 116.235 116.504C115.035 116.504 114.062 117.479 114.062 118.682C114.062 119.884 115.035 120.859 116.235 120.859Z"
                fill="var(--arrow-z)" />
            </g>
            <defs>
              <clipPath id="clip0_242_16529">
                <rect width="230" height="193" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div v-if="gyro.noGyro || (!gyro.dataStarted && gyro.DeviceOrientationEnabled)" class="is-flex is-justify-content-center">
          <b-button :label="$t('exp_external.activateSensors')" expanded type="is-primary" size="is-medium"
            @click="$refs.myGyro.RequestGyro()" />
        </div>
      </div>
    </gyro-access>
  </div>
</template>

<style scoped lang="scss">
.gyro-wrapper {
  width: 100%;
}
.tutorial {
  width: 250px;
  max-width: 250%;
  height: 200px;
}
.requestGyro {
  align-items: center;
}
</style>

<script>
import GyroAccess from '@/components/GyroAccess.vue';
export default {
  components: {
    GyroAccess
  },
  props: {
    block:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      EmitCoolingDown: false,
    };
  },
  computed: {},
  methods: {
    gyroStarted(){
      console.log('gyroStarted')
    },
    noGyroPermission(){
      console.log('noGyroPermission')
    },
    noGyroAvailable(){
      console.log('noGyroAvailable')
    },
    CallibrateGyro(){
      this.$refs.myGyro.Callibrate()
    },
    callibrated() {
      this.$socket.client.emit('gameMessage', {
        type: 'callibrated'
      })
      this.$sfxPlay('resetthecenter')
    },
    UpdateGyro(data) {
      if (this.EmitCoolingDown) return // cooldown
      // Agrego data al objeto a enviar. Segun opciones
      var emitData = {}
      if(this.block.enableRange){
        emitData.coords = data.constrained
      }
      if(this.block.sendOrientation){
        emitData.orientation = data.orientation
      }
      if(this.block.sendAcceleration){
        emitData.acceleration = data.acceleration
      }
      // Envio el socket
      this.$socket.client.emit('gameMessage', {
        type: 'controllerData',
        controllerType: 'gyro',
        name: this.block.blockName,
        input: emitData        
      });

      this.EmitCoolingDown = true;
      setTimeout(() => {
        this.EmitCoolingDown = false
      }, this.block.dataInterval)
    },
  },
  mounted() {
    //
  },
};
</script>
